body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(./asserts/images/bb-bg.jpg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.Overview, .Team {
  background-color: rgba(186, 186, 186, 0.5);
}

.menu-wrap {
  background-image: url(./asserts/images/bb-bg.jpg);
}

.menu {
  background-color: unset;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.textSmall {
  font-size: x-small;
}

.textLarge {
  font-size: large;
}

.textRed {
  color: red;
}

.textGreen {
  color: green;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
  margin-right: 3px;
  background-color: rgba(186, 186, 186, 0.7);
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.icon-default {
  font-size: 35px;
  background-color: rgba(186, 186, 186, 0.7);
}

.accordion-item, .accordion-button {
  background-color: rgba(186, 186, 186, 0.3);
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: rgba(186, 186, 186, 0.5);
}

.greyBg {
  background-color: rgba(186, 186, 186, 0.7);
}

.lightGreyBg {
  background-color: rgba(186, 186, 186, 0.3);
}

.bold {
  font-weight: bold;
}

.staticText {
  background-color: rgba(186, 186, 186);
  padding: 2px;
}

.boxSize {
  min-height: 300px;
}

.current td {
  background-color: rgba(0, 255, 0, 0.4);
}

.currentDay td {
  background-color: rgba(255, 0, 0, 0.4);
}

.currentData td {
  background-color: rgba(255, 213, 0, 0.4);
}